export const mobileRouterList = [
	
	{
		path      : '/',
		name      : 'layout',
		component : () => import('../pages/layout/layout.vue'),
		redirect  : { name : 'index' },
		children  : [
			{
				path      : '/',
				name      : 'index',
				component : () => import('../pages'),
				meta      : {
					title     : '首页',
					keepAlive : false,
				},
			},
			{
				path      : 'login',
				alias     : 'login.html',
				name      : 'login',
				component : () => import('../pages/login.vue'),
				meta      : {
					title     : '用户登录',
					keepAlive : false,
				},
			},
			{
				path      : 'register',
				alias     : 'register.html',
				name      : 'register',
				component : () => import('../pages/register.vue'),
				meta      : {
					title     : '用户注册',
					keepAlive : false,
				},
			},
			{
				path      : 'agreement',
				alias     : 'agreement.html',
				name      : 'agreement',
				component : () => import('../pages/agreement.vue'),
				meta      : {
					title     : '注册协议',
					keepAlive : false,
				},
			},
			{
				path      : 'privacy',
				alias     : 'privacy.html',
				name      : 'privacy',
				component : () => import('../pages/privacy.vue'),
				meta      : {
					title     : '免责声明',
					keepAlive : false,
				},
			},
			{
				path      : 'forbiddens',
				alias     : 'forbiddens.html',
				name      : 'forbiddens',
				component : () => import('../pages/forbiddens.vue'),
				meta      : {
					title     : '禁售产品',
					keepAlive : false,
				},
			},
			{
				path      : 'password_forget',
				alias     : 'password_forget.html',
				name      : 'password_forget',
				component : () => import('../pages/password_forget.vue'),
				meta      : {
					title     : '找回密码',
					keepAlive : false,
				},
			},
			// {
			//   path: 'password_reset',
			//   name: 'password_reset',
			//   component: () => import('../pages/password_reset.vue'),
			//   meta:{
			//     title: '重置密码',
			//     keepAlive: false
			//   }
			// },
			{
				path      : 'contact',
				alias     : 'contact.html',
				name      : 'contact',
				component : () => import('../pages/contact.vue'),
				meta      : {
					title     : '联系我们',
					keepAlive : false,
				},
			},
			{
				path      : 'help',
				alias     : 'help.html',
				name      : 'help',
				component : () => import('../pages/help.vue'),
				meta      : {
					title     : '联系我们',
					keepAlive : false,
				},
			},
			{
				path      : 'help/detail',
				alias     : 'help/detail.html',
				name      : 'help_detail',
				component : () => import('../pages/help_detail.vue'),
				meta      : {
					title     : '帮助',
					keepAlive : false,
				},
			},
			{
				path      : 'help/detail/:id?',
				alias     : 'help/detail.html',
				name      : 'help_detail',
				component : () => import('../pages/help_detail.vue'),
				meta      : {
					title     : '帮助',
					keepAlive : false,
				},
			},
			{
				path      : 'news/detail/:id?',
				alias     : 'news/detail.html',
				name      : 'news_detail',
				component : () => import('../pages/help_detail.vue'),
				meta      : {
					title     : '帮助',
					keepAlive : false,
				},
			},
			{
				path      : 'news/detail',
				alias     : 'news/detail.html',
				name      : 'news_detail',
				component : () => import('../pages/help_detail.vue'),
				meta      : {
					title     : '帮助',
					keepAlive : false,
				},
			},
			{
				path      : 'product',
				alias     : 'product.html',
				name      : 'product',
				component : () => import('../pages/product.vue'),
				meta      : {
					title     : '企业资质',
					keepAlive : false,
				},
			},
			{
				path      : 'orderquery',
				alias     : 'orderquery.html',
				name      : 'orderquery',
				component : () => import('../pages/orderquery.vue'),
				meta      : {
					title     : '订单查询',
					keepAlive : false,
				},
			},
			{
				path      : 'tousu/add',
				alias     : 'tousu/add.html',
				name      : 'tousu_add',
				component : () => import('../pages/tousu_add.vue'),
				meta      : {
					title     : '投诉订单',
					keepAlive : false,
				},
			},
			{
				path      : 'tousu_search',
				alias     : 'tousu_search.html',
				name      : 'tousu_search',
				component : () => import('../pages/tousu_search.vue'),
				meta      : {
					title     : '投诉查询',
					keepAlive : false,
				},
			},
			{
				path      : 'tousu_content',
				alias     : 'tousu_content.html',
				name      : 'tousu_content',
				component : () => import('../pages/tousu_content.vue'),
				meta      : {
					title     : '投诉详情',
					keepAlive : false,
				},
			},
		],
	},
]
