// 本地缓存服务
const PREFIX = 'faka_'

// config 模块
const CONFIG_INFO = `${PREFIX}config_`

// index 模块
const INDEX_PREFIX = `${PREFIX}user_`
const INDEX_TOKEN = `${INDEX_PREFIX}token`
const INDEX_INFO = `${INDEX_PREFIX}info`

// user 模块
const USER_PREFIX = `${PREFIX}user_`
const USER_TOKEN = `${USER_PREFIX}token`
const USER_INFO = `${USER_PREFIX}info`

// 储存
const set = (key, data) => {
  localStorage.setItem(key, data)
}
// 读取
const get = (key) => {
  return localStorage.getItem(key)
}

export default {
  set,
  get,
  CONFIG_INFO,
}
