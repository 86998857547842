//引入文件
import axios from 'axios'

//全局配置
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 1000 * 5,
  withCredentials: true,

})

// vue前端项目后端set-cookie，前端的cookie里什么东西都没有，怎么解决？：
// 1、本地改下host就好了，改成和cookie的domain相同的二级域名，当然cookie的domain也得是二级的。
// 2、axios跨域,Set-Cookie不生效,axios默认不写入cookie：https://www.zhihu.com/question/377413467/answer/1064227421
// service.defaults.withCredentials = true

// 响应拦截
service.interceptors.response.use(res => {
  const {
    status,
    data,
    statusText
  } = res
  if (status === 200) {
    // 请求成功
    return data
  } else if (status === 400) {
    // 请求失败
    return data
  }
})

// 请求方法
// request.post('/api',{xxx:xxx})
function request (options) {
  options.method = options.method || 'get'

  if (options.method.toLowerCase() === 'get') {
    options.params = options.data
  }

  return service(options)
}

// 实现方法
['get', 'post', 'put', 'delete'].forEach(item => {
  request[item] = (url, data) => {
    return request({
      url,
      data,
      method: item
    })
  }
})

export default request
