export const mobile_pay_theme_2020_mobile_ldfaka = [

  {
    path: '/details/:linkid',
    name: '2020_mobile_ldfaka',
    component: () => import('../page/mobile_2020_ldfaka'),
    meta: {
      title: '商品详情',
      keepAlive: false
    }
  },

  {
    path: '/liebiao/:linkid',
    name: '2020_mobile_ldfaka',
    component: () => import('../page/mobile_2020_ldfaka'),
    meta: {
      title: '商品详情',
      keepAlive: false
    }
  },

  {
    path: '/links/:linkid',
    name: '2020_mobile_ldfaka',
    component: () => import('../page/mobile_2020_ldfaka'),
    meta: {
      title: '商品详情',
      keepAlive: false
    }
  },
]

