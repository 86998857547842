import ConfigApi      from '@/views/index/api/index/configApi'
import storageService from '@/utils/storageService'

const configModule = {
	namespaced : true,
	state      : {
		configInfo : storageService.get(storageService.CONFIG_INFO) ? JSON.parse(storageService.get(storageService.CONFIG_INFO)) : {
			baizi_logo_big : '',
		}, //eslint-disable-line
	},
	getters    : {},
	mutations  : {
		SET_CONFIG_INFO (state, configInfo) {
			// 更新本地缓存
			storageService.set(storageService.CONFIG_INFO, JSON.stringify(configInfo))
			// 更新state
			state.configInfo = configInfo
		},
	},
	actions    : {
		// 获取网站信息
		getConfigInfo (context) {
			return new Promise((resolve, reject) => {
				ConfigApi.getConfigInfo().then(res => {
					// 动态缓存3
					context.commit('SET_CONFIG_INFO', res.data.list)
					resolve(res)
				}).catch(err => {
					reject(err)
				})
			})
		},
	},
	modules    : {},
}

export default configModule
