const elLoadingDuration = 1500
const elLoadingCloseDuration = 500

const WEBSITE = '极绿发卡自动发卡网'
const WEBSITE_NAME = '极绿发卡'
const PUBLIC_TITLE_DEFAULT = '极绿发卡自动发卡网 - www.jilvfaka.com'
const PUBLIC_TITLE = ' - 极受用户欢迎的自动发卡平台 -'
const PUBLIC_DESCRIPTION = '极绿发卡平台是广州相遇盛世科技有限公司旗下的一款虚拟数字产品在线交易服务系统，实现买家与商户之间的双向自动交易服务。'
const PUBLIC_KEYWORD = '极绿发卡,自动发卡平台,发卡网,发卡网站,卡密自动发货,虚拟商品自动发货,卡密寄售,自动售卡,卡密自动售卖,猴发卡自动发卡平台'

export default {
  elLoadingDuration,
  elLoadingCloseDuration,
  WEBSITE,
  WEBSITE_NAME,
  PUBLIC_TITLE_DEFAULT,
  PUBLIC_TITLE,
  PUBLIC_DESCRIPTION,
  PUBLIC_KEYWORD,

}
