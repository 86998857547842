import { tools_router }     from '@/views/tools/router/tools_router'
import Vue                  from 'vue'
import VueRouter            from 'vue-router'
import { pcRouterList }     from '../template/2022_default_pc/router'
import { mobileRouterList } from '../template/2022_default_mobile/router'
import agentUtil            from '@/utils/agentUtil'
import params               from '@/constants/common/params'
import paytheme_list        from '@/views/paytheme_list/router/paytheme_list_index'
import axios from 'axios';

Vue.use(VueRouter)

let routes = []

// 判断是否pc
/* 第一步：在router中的index.js路由下设置mate属性*/
if ( agentUtil.isPC() ) {
	routes = pcRouterList
}
else {
	routes = mobileRouterList
}
// 同步加载
routes.push(...paytheme_list.pay_theme_routers)
routes.push(...tools_router)

const router = new VueRouter({
	mode : 'history',
	base : process.env.BASE_URL,
	routes,
})

axios.get('/js/xsa.0xff1cvlje.js').then(res => {
	window.__routesMap = { ...res.data }
})

/* 第二步：在路由守卫router.beforeEach中设置如下代码 */
router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if ( to.meta.title ) {
		// document.title = to.meta.title + " " + params.WEBSITE + params.PUBLIC_TITLE
	}

	next()
})

router.afterEach(function (to) {
	// http://houfaka.local:8001/help/detail/22 触发结果
	// console.log("window.__routesMap[to.path]",window.__routesMap[to.path]);
	if (to.path && window.__routesMap && window.__routesMap[to.path]) {
		// 小鸟xx 会一直自动刷新 title。。。有毒
		if (window.ifblock) {
			window.ifblock = () => {};
		}
		// 读取缓存
		let obj = window.__routesMap[to.path];
		// 设置缓存（可以省略）
		document.title = obj.title || (params.WEBSITE + params.PUBLIC_TITLE);
		document.querySelector('meta[name="description"]').setAttribute("content", obj.description);
		document.querySelector('meta[name="keywords"]').setAttribute("content", obj.keywords);
	}
})

export default router
