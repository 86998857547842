import axiosRequest from '@/utils/axiosRequest'

// 获取网站信息
const getConfigInfo = () => {
  return axiosRequest.get('api_index/ConfigInfo/getConfigInfo')

}

export default {
  getConfigInfo
}
