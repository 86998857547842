import urlLocationUtil                          from '@/utils/urlLocationUtil'
import payThemeApi                              from '@/views/paytheme_list/api/payThemeApi'
import agentUtil                                from '@/utils/agentUtil'
import { pc_pay_theme_2020_default }            from '@/views/paytheme_list/template/2020_default/router/2020_default_index'
import { pc_pay_theme_2021_default }            from '@/views/paytheme_list/template/2021_default/router/2021_default_index'
import { mobile_pay_theme_2020_mobile_ldfaka }  from '@/views/paytheme_list/template/2020_mobile_ldfaka/router'
import { mobile_pay_theme_2023_mobile_default } from "@/views/paytheme_list/template/2023_mobile_default/router";

//加载路由
let pay_theme_routers = []

// 获取控制器名
let urlPathInfo = urlLocationUtil.getUrlPathinfo()

// 异步执行
// payThemeApi.getPayThemeName({
//   linktype: urlPathInfo[1],
//   linkid: urlPathInfo[2],
// }).then(res => {
//   // 判断pc
//   if (agentUtil.isPC()) {
//     if (res.data.list.paytheme === '2022_default_pc') {
//       pay_theme_routers = pc_pay_theme_2020_default
//     } else if (res.data.list.paytheme === '2021_default') {
//       pay_theme_routers = pc_pay_theme_2021_default
//     }
//   }
// })

var number = ["details", "liebiao", "links"];
var check_is_paytheme = number.indexOf(urlPathInfo[1].toLowerCase())
if (check_is_paytheme > -1) {
  // 同步执行
  let payThemeNameRes = payThemeApi.getPayThemeNameSync({
    linktype: urlPathInfo[1],
    linkid: urlPathInfo[2],
  })
  // 判断pc
  if (agentUtil.isPC()) {
    if (payThemeNameRes.data.list.paytheme === '2020_default') {
      pay_theme_routers = pc_pay_theme_2020_default
    } else if (payThemeNameRes.data.list.paytheme === '2021_default') {
      pay_theme_routers = pc_pay_theme_2021_default
    }
  }
  // wap
  else {
    if (payThemeNameRes.data.list.mobile_paytheme === '2020_mobile_ldfaka') {
      pay_theme_routers = mobile_pay_theme_2020_mobile_ldfaka
    }
    else if (payThemeNameRes.data.list.mobile_paytheme === '2023_mobile_default') {
      pay_theme_routers = mobile_pay_theme_2023_mobile_default
    }
  }
} else {

}

export default {
  pay_theme_routers
}
