export const mobile_pay_theme_2023_mobile_default = [

  {
    path: '/details/:linkid',
    name: '2023_mobile_default',
    component: () => import('../page/2023_mobile_default'),
    meta: {
      title: '商品详情',
      keepAlive: false
    }
  },

  {
    path: '/liebiao/:linkid',
    name: '2023_mobile_default',
    component: () => import('../page/2023_mobile_default'),
    meta: {
      title: '商品详情',
      keepAlive: false
    }
  },

  {
    path: '/links/:linkid',
    name: '2023_mobile_default',
    component: () => import('../page/2023_mobile_default'),
    meta: {
      title: '商品详情',
      keepAlive: false
    }
  },
]

