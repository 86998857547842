import axiosRequest from '@/utils/axiosRequest'
import urlLocationUtil from '@/utils/urlLocationUtil'
import $ from 'jquery'

// 查看paythemeName
const getPayThemeName = () => {
  let urlPathInfo = urlLocationUtil.getUrlPathinfo()
  return new Promise(async (resolve, reject) => {
    await axiosRequest.post('/api_paypage/get_pay_theme', {
      linktype: urlPathInfo[1],
      linkid: urlPathInfo[2],
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}
// 查看paythemeName：同步方法
const getPayThemeNameSync = ({
  linktype,
  linkid,
}) => {
  let urlPathInfo = urlLocationUtil.getUrlPathinfo()
  let result = {}
  $.ajax({
    async: false,
    type: "POST",
    data: {
      linktype: linktype,
      linkid: linkid,
    },
    url: process.env.VUE_APP_BASE_API + "/api_paypage/get_pay_theme",
    dataType: 'json',
    success: function (data) {
      result = data;
    }
  });

  return result
}

export default {
  getPayThemeName,
  getPayThemeNameSync,
}
