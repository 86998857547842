// 解析url query参数
function queryURLparams(url) {
  let obj = {}
  if (url.indexOf('?') < 0) return obj
  let arr = url.split('?')
  url = arr[1]
  let array = url.split('&')
  for (let i = 0; i < array.length; i++) {
    let arr2 = array[i]
    let arr3 = arr2.split('=')
    obj[arr3[0]] = arr3[1]
  }
  return obj

}

/**
 * 获取get参数
 * @param variable
 * @returns {string | null}
 */
function getQueryVariable(variable)
{
  // 获取参数
  let query = window.location.search.substring(1);
  let vars = query.split("&");
  for (let i=0;i<vars.length;i++) {
    let pair = vars[i].split("=");
    if(pair[0] == variable){return pair[1];}
  }
  return(false);
}

/**
 * 获取 url 中模块|控制器|方法名称
 * @param index
 * @returns {string | string}
 */
function getUrlPathName(index) {
  index = index || 1;
  //获取当前窗口的 hostname
  let url = window.location.pathname  ;
  let arr = url.split("/");
  return arr[index] || '';
}

/**
 * 获取 url 中模块|控制器|方法名称
 * @returns {Array | Array}
 */
function getUrlPathinfo() {
  //获取当前窗口的 hostname
  let url = window.location.pathname  ;
  let arr = url.split("/");
  return arr || [];
}

/*
var url      = window.location.href;  // 返回完整 URL (https://www.runoob.com/html/html-tutorial.html)
var pathname = window.location.pathname; // 返回路径部分 (/html/html-tutorial.html)
var origin   = window.location.origin;   // 返回基础 URL (https://www.runoob.com/)
var domain = document.domain;  // 返回域名部分 (www.runoob.com)
 */
/**
 * 获取urlRoot
 * @returns {string}
 */
function getUrlRoot () {
  return window.location.origin
}


export default {
  queryURLparams,
  getQueryVariable,
  getUrlPathName,
  getUrlPathinfo,
  getUrlRoot,
}

