export const pc_pay_theme_2021_default = [

  {
    path: '/details/:linkid',
    name: 'pc_2021_default',
    component: () => import('../page/pc_2021_default'),
    meta: {
      title: '商品详情',
      keepAlive: false
    }
  },

  {
    path: '/liebiao/:linkid',
    name: 'pc_2021_default',
    component: () => import('../page/pc_2021_default'),
    meta: {
      title: '商品详情',
      keepAlive: false
    }
  },

  {
    path: '/links/:linkid',
    name: 'pc_2021_default',
    component: () => import('../page/pc_2021_default'),
    meta: {
      title: '商品详情',
      keepAlive: false
    }
  },
]

