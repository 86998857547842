import Vue from 'vue'
import App from './App.vue'
import router from './router/index_router'
import store from './store'
import '@/plugins/axios'
import '@/plugins/element.js'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => {
    document.body.classList.remove('body-trans');
    return h(App)
  }
}).$mount('#index')
